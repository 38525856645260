import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import RichText from "../RichText";
import "./styles.scss";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";

export type PopupInterface = {
  data: {
    name: string;
    heading: string;
    _rawBody?: PortableText;
    ctaLabel: string;
    ctaURL: string;
    skipLabel: string;
    _rawPolicy?: PortableText;
    _rawHeroImage?: ImageInterface;
  }
}

const Popup: FunctionComponent<PopupInterface> = ({ data }) => {
  const [showModal, setShowModal] = useState(true);
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  useEffect(() => {
    handleScroll();
  }, [])

  function handleScroll(): void {
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="popup"
        onEnterred={() => handleScroll()}
        backdrop={false}
      >
        <Modal.Body data-testid="popup-body" className="popup-body">
          <div className={`popup-image ${!data._rawHeroImage ? "no-image" : ""}`}>
            {data._rawHeroImage && (
              <picture>
                <source
                  media={"(min-width: 1200px)"}
                  srcSet={
                    urlBuilder
                      .image(data._rawHeroImage)
                      .auto("format")
                      .quality(80)
                      .width(500)
                      .height(333)
                      .format("webp")
                      .url() || undefined
                  }
                />
                <source
                  media={"(min-width: 768px)"}
                  srcSet={
                    urlBuilder
                      .image(data._rawHeroImage)
                      .auto("format")
                      .quality(80)
                      .width(500)
                      .height(333)
                      .format("webp")
                      .url() || undefined
                  }
                />
                <img
                  width="501"
                  height="466"
                  className="img-fluid secondary-image"
                  src={
                    urlBuilder
                      .image(data._rawHeroImage)
                      .auto("format")
                      .quality(80)
                      .width(500)
                      .height(333)
                      .format("webp")
                      .url() || undefined
                  }
                  loading={"lazy"}
                  alt={data._rawHeroImage.alt as string}
                />
              </picture>
            )}
          </div>
          <div className="popup-content">
            <h3>
              <span className="heading-line-1">{data.heading}</span>
            </h3>
            {data._rawBody && <RichText data={data._rawBody} />}
            <div className="buttons">
              <Button href={data.ctaURL} className="primary" size="lg">
                {data.ctaLabel}
              </Button>
              {data.skipLabel && (
                <Button
                  href="#modal-closed"
                  size="sm"
                  onClick={() => setShowModal(false)}
                  className="secondary"
                  variant="light"
                >
                  {data.skipLabel}
                </Button>
              )}
            </div>
            <div className="popup-policy">{data._rawPolicy && <RichText data={data._rawPolicy} />}</div>
          </div>
        </Modal.Body>
        <button className="popup-x" data-dialog-hide="" aria-label="Close Pop Up" onClick={() => setShowModal(false)}>
          X
        </button>
      </Modal>
    </>
  );
}

export default Popup;