const faqExtractor = (slug: string, data: Record<string, unknown>, siteName: string, siteUrl: string) => {
  const url = `${siteUrl}${slug}`;
  const renderMainEntity = (content: Record<string, unknown>) => {
    //@ts-ignore
    return content.map((item: Record<string, unknown>) => {
      //@ts-ignore
      return item.faqItems.map((item: { answer: { children: { text: string }[] }[]; question: string }) => {
        const answerText: string[] = [];
        item.answer.forEach(answer => {
          answer.children.forEach(child => {
            answerText.push(child.text);
          });
        });

        return {
          "@type": "Question",
          name: item.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: answerText.join(" ")
          }
        };
      });
    });
  };

  return {
    "@id": url,
    mainEntity: renderMainEntity(data.content as Record<string, unknown>)
  };
};
export default faqExtractor;
