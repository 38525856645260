import { TagInterface } from "../../../types/SanityTypes";

const articleExtractor = (
  slug: string,
  data: Record<string, unknown>,
  language: string,
  siteUrl: string,
  siteName: string
) => {
  const url = `${siteUrl}${slug}`;
  const tags = (data.tags as TagInterface[]).map(tag => tag.tagName);

  return {
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url
    },
    headline: data.headline,
    datePublished: formatDate(data.publishedAt as string) || formatDate(data._createdAt as string),
    dateModified: formatDate(data.publishedAt as string) || formatDate(data._updatedAt as string),
    author: {
      "@type": "Person",
      name: "Sure"
    },
    publisher: {
      "@type": "Organization",
      name: siteName,
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}/unilever-logo.png`
      }
    },
    accessMode: "Textual",
    inLanguage: language,
    isFamilyFriendly: true,
    keywords: tags,
    sourceOrganization: {
      name: siteName,
      url: siteUrl
    },
    about: {
      name: siteName
    }
  };
};

const formatDate = (date?: string) => {
  return date?.split("T")[0];
};

export default articleExtractor;
