import React, { FunctionComponent, useContext } from "react";
import { ArrowUp } from "../../images/icons/arrow-up";
import Accessibility from "../Accessibility";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event66 } from "../../analytics/event66";

export interface GradientInterface {
  degree: number;
  startColor: string;
  endColor: string;
  arrowFill?: boolean;
}

const BackToTop: FunctionComponent<GradientInterface> = ({ degree, startColor, endColor, arrowFill }) => {
  const { backToTop } = useContext(LocalizedContext).sanityAccessibility || {};
  const colorGradient = `linear-gradient(${degree}deg, ${startColor} 0%, ${endColor} 100%)`;

  function smoothScroll(targetPosition: number) {
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth"
    });

    return new Promise<void>((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 2000);

      const scrollHandler = () => {
        if (self.pageYOffset === targetPosition) {
          window.removeEventListener("scroll", scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };
      window.addEventListener("scroll", scrollHandler);
    });
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event66(backToTop?backToTop:"back to top");
    smoothScroll(0).then(() => {
      const firstFocusableElement = document.body.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )[0] as HTMLElement;
      firstFocusableElement.focus();
    });
  };

  return (
    <section className="back-to-top" data-testid="back-to-top">
      <button type="button" onClick={handleClick} style={{ background: colorGradient }}>
        <ArrowUp arrowFill={arrowFill} />
        <Accessibility location={backToTop} />
      </button>
    </section>
  );
};

export default BackToTop;
