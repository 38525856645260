import React from "react";

export const ArrowUp = ({ arrowFill }: ArrowUpProps) => {
  return (
    <div className={arrowFill ? "arrow-fill" : undefined}>
      <svg width="120px" height="100px" viewBox="0 0 75 93" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Arrow Up</title>
        <g id="CONTENT-PAGES" stroke="white" strokeWidth="1" fill="black" fillRule="evenodd">
          <g id="PURPOSE_Desktop" transform="translate(-646.000000, -5721.000000)">
            <g id="Back-to-top" transform="translate(548.000000, 5630.000000)">
              <g
                id="REXONA-GRADIENTS-Primary-10-Copy"
                transform="translate(135.364092, 135.829609) rotate(-210.000000) translate(-135.364092, -135.829609) translate(36.364092, 36.829609)"
              >
                <use id="Mask" fill="url(#linearGradient-1)" href="#path-2" />
              </g>
              <path
                d="M182,137.565905 C182,138.356766 181.198276,139.147627 180.396552,139.147627 L94.3448276,139.147627 L127.75,172.100176 C128.284483,172.627417 128.284483,173.681898 127.75,174.472759 C127.482759,174.73638 126.948276,175 126.681034,175 C126.413793,175 125.87931,174.73638 125.612069,174.472759 L89.2672414,138.620387 C89,138.356766 89,138.356766 89,138.093146 C89,137.829525 89,137.565905 89,137.565905 C89,137.565905 89,137.565905 89,137.565905 L89,137.565905 C89,137.302285 89,137.038664 89,137.038664 C89,136.775044 89.2672414,136.775044 89.2672414,136.511424 L125.87931,100.395431 C126.413793,99.8681898 127.482759,99.8681898 128.284483,100.395431 C128.818966,100.922671 128.818966,101.977153 128.284483,102.768014 L94.3448276,135.984183 L180.396552,135.984183 C181.198276,135.984183 182,136.775044 182,137.565905 Z"
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform="translate(135.500000, 137.500000) rotate(-270.000000) translate(-135.500000, -137.500000) "
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

type ArrowUpProps = {
  arrowFill: boolean | undefined;
};
