import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  SanityGraphQLConnection,
  Seo,
  SocialMediaChannel
} from "../../types/SanityTypes";
import { graphql, PageProps } from "gatsby";
import SEO from "../../components/Seo";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import StructuredData from "../../components/StructuredData";
import BackToTop from "../../components/BackToTop";
import BlockRenderer from "../../components/BlockRenderer";
import { LocalisedPageContext } from "../../types/PageTypes";
import Popup, { PopupInterface } from "../../components/Popup";

type HomepageProps = {
  home: {
    seo: Seo;
    slug: {
      current: string;
    };
    backgroundColor?: {
      title: string;
      value: string;
    };
    backToTop?: boolean;
    name: string;
    heading: string;
    _rawLogo: ImageInterface;
    logo: ImageInterface;
    landingSections: LandingSectionsInterface[];
    popup?: PopupInterface;
  };
  socialMedia: SanityGraphQLConnection<SocialMediaChannel>;
} & ContactInterface &
  LocalizedContextInterface;

export interface ContactInterface {
  contact: {
    address: string;
    city: string;
    country: string;
    postcode: string;
    telephone: string;
    contactNo: {
      contactType: string;
      telephoneNo: string;
    }[];
  };
}

type HomePageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String, $market: String) {
    socialMedia: allSanitySocialMediaChannel(filter: {market: { eq: $market }}) {
      edges {
        node {
          url
        }
      }
    }
    contact: sanityContactInformation(i18n_lang: { eq: $language }, market: { eq: $market }) {
      address
      city
      country
      postcode
      telephone
      contactNo {
        contactType
        telephoneNo
      }
    }
    home: sanityHomePage(_id: { eq: $_id }) {
      ...HomePageFieldsFull
    }
    ...LocalizedContext
  }
`;

const HomePage = (props: PageProps<HomepageProps, HomePageContext>) => {
  const homePage = props.data.home;
  const logo = props.data.home?._rawLogo || props.data.home?.logo;
  const { siteUrl, htmlLang, siteName } = useSiteMetadata(props.pageContext.language);
  const backToTopGradientProps = {
    degree: 25,
    startColor: "var(--blue-bright-color)",
    endColor: "var(--green-color)"
  };

  const structuredData = { logo, ...props.data.socialMedia, ...props.data.contact, siteName };

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const updateShowPopup = () => {
    const isSure = siteUrl.includes('gb') || siteUrl.includes('uk');

    if(isSure) {
      setTimeout(() => {
        setShowPopup(true);
      }, 15000);
    } else {
      setShowPopup(true);
    }
  }

  useEffect(() => {
    updateShowPopup();
  }, [])

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={homePage?.seo.metaTitle}
      pageType={"home"}
      contentType={"Homepage"}
      language={props.pageContext.language}
      backgroundColor={props.data.home.backgroundColor}
    >
      <StructuredData
        type={"Organization"}
        name={siteName}
        description={homePage?.seo.metaDescription}
        slug={siteUrl}
        image={{
          url: logo?.asset.url as string
        }}
        data={structuredData}
        customData={homePage.seo.jsonld}
        language={htmlLang}
      />
      <SEO
        title={homePage?.seo.metaTitle}
        description={homePage?.seo.metaDescription}
        imageUrl={logo?.asset.url}
        imageAlt={logo?.alt}
      />
      {homePage?.landingSections && (
        <>
          {homePage.landingSections.map((section, index) => (
            <BlockRenderer key={"landingSection" + index} index={index} section={section} />
          ))}
        </>
      )}
      {homePage?.popup && showPopup && <Popup data={homePage?.popup} />}
      {homePage?.backToTop && (
        <BackToTop
          degree={backToTopGradientProps.degree}
          startColor={backToTopGradientProps.endColor}
          endColor={backToTopGradientProps.startColor}
          arrowFill={false}
        />
      )}
    </Layout>
  );
};

export default HomePage;
