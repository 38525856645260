const productExtractor = (
  slug: string,
  data: {
    _id: string;
    productLandingPage?: {
      slug: {
        current: string;
      };
    };
  },
  language: string,
  siteUrl: string,
  siteName: string,
  reviews?: {
    ratingValue?: number;
    reviewCount?: number;
    '@type': string,
    bestRating: number,
    ratingCount: number
  }
) => {
  const productSlug = data.productLandingPage ? data.productLandingPage.slug.current : "products";
  const url = `${siteUrl}/${productSlug}/${slug}/`;

  if(reviews?.ratingValue === undefined || reviews?.reviewCount === 0) {
    return {
      brand: siteName,
      url: url,
      gtin8: data._id
    }
  }

  else {
    return {
      brand: siteName,
      url: url,
      gtin8: data._id,
      aggregateRating: {
        "@type": "aggregateRating",
        ratingValue: reviews?.ratingValue,
        reviewCount: reviews?.reviewCount,
        ratingCount: reviews?.ratingCount,
        bestRating: reviews?.bestRating
      }
    }
  }
}

export default productExtractor;