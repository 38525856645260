import { ImageInterface } from "../../../types/SanityTypes";

const organisationExtractor = (slug: string, data: Record<string, unknown>, language: string, siteUrl: string) => {
  const socialMedia = () => {
    const socialMediaUrls: string[] = [];
    (data.edges as Record<string, unknown>[]).map(item => {
      socialMediaUrls.push((item.node as Record<string, unknown>).url as string);
    });
    return socialMediaUrls;
  };

  const renderContactPoint = () => {
    const contactNo = (data.contactNo as Array<unknown>) || [];
    return (contactNo as Array<unknown>).map(item => {
      return {
        "@type": "contactPoint",
        telephone: (item as Record<string, string>).telephoneNo,
        contactType: (item as Record<string, string>).contactType
      };
    });
  };
  return {
    sameAs: socialMedia(),
    url: siteUrl,
    logo: (data.logo as ImageInterface)?.asset.url || "",
    legalName: data.siteName,
    leiCode: "01234345",
    address: {
      "@type": "PostalAddress",
      streetAddress: data.address,
      addressRegion: data.city,
      addressCountry: data.country,
      postalCode: data.postcode
    },
    telephone: data.telephone,
    contactPoint: renderContactPoint()
  };
};

export default organisationExtractor;
